import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { connect } from "react-redux";
import { setTriggered, setInterruption } from "./FacePlot/FacePlot.reducer";
import { PlayCircleOutline, PauseCircleOutline } from '@material-ui/icons';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const mapStateToProps = (state) => ({
    triggered: state.animation.triggered
})

const mapDispatchToProps = (dispatch) => ({
    setTriggered: () => dispatch(setTriggered()),
    setInterruption: () => dispatch(setInterruption())
})

const styles = theme => ({
    button: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        flexGrow: 0,
        alignSelf: "center",
    },
});

const PlayButton = (props) => {
    const { classes, triggered } = props;

    // fixme: animate start Playing
    if ( triggered ){
       return (
            <Tooltip title="Pause animation" placement="top">
                <IconButton 
                    onClick={ props.setInterruption } 
                    variant="contained" 
                    color="primary" 
                    classes={{ root: classes.button }}>
                    <PauseCircleOutline fontSize="large" />
                </IconButton>
            </Tooltip>
        );
    } else {
        return (
            <Tooltip title="Start animation" placement="top">
                <IconButton
                    id="play_button"
                    onClick={ props.setTriggered } 
                    variant="contained" 
                    color="primary" 
                    classes={{ root: classes.button }}
                    >
                    <PlayCircleOutline fontSize="large"  />
                </IconButton>
            </Tooltip>
        );
    }
}

PlayButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PlayButton));