import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import LabelList from "./LabelList";

const styles = (theme) => ({
    list: {
        width: "100%"
    },
    container: {
        flex: "1 1 0",
        display: "flex",
        flexDirection: "column",
        overflow: "auto !important"
    },
    selection: {
        flex: 1,
        overflowY: "auto",
    },
})

const FunctionalSidebar = (props) => {
    const { classes } = props;
    return (
        <div className={ classes.container }>
            <LabelList classes={{ container: classes.selection }} />
        </div>
    )
}

export default withStyles(styles)(FunctionalSidebar);