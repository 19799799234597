import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';

import HelpIcon from '@material-ui/icons/Help';
import Divider from '@material-ui/core/Divider';

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: "50%",
        backgroundColor: '#f5f5f5',
        //backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: 'none',
        top: "50%",
        left: "50%",
        fontWeight: "bold",
        transform: "translate(-50%, -50%)",
    },
});



class HelpModal extends React.Component{
    state = {
        open: false,
    };

    handleClick = () => {
        this.setState({ open: !this.state.open });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render(){
        const { open } = this.state;
        const { classes } = this.props;

        return (
            <div>
                <Tooltip enterDelay={100} title="About" placement="bottom">
                    <IconButton
                        aria-label="More"
                        aria-owns={open ? 'long-menu' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                        >
                        <HelpIcon />
                    </IconButton>
                </Tooltip>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={open}
                    onClose={this.handleClose}
                >
                    <div className={classes.paper} style={{textAlign:'center'}}>
                        <Typography variant="button" id="modal-title" gutterBottom>
                            <b>thisemotiondoesnotexist</b>
                        </Typography>
                        <Typography variant="body1" id="simple-modal-description">
                            Interactive visualization of emotional facial expression perception
                            <Divider classes={{root: classes.dividerGutters}} variant="middle" />
                        </Typography>
                        <br/>
                        <Link href="https://github.com/jschn/" className={classes.link} target="_top">
                            Jan N. Schneider
                        </Link>
                        <Typography>
                            Project design, data collection and statistical analysis
                        </Typography>
                        <br/>
                        <Link href="https://github.com/knthls/" className={classes.link} target="_top">
                        Christian Knauth
                        </Link>
                        <Typography>
                            React app
                        </Typography>
                        <br/>
                        <Link href="https://www.psychologie.hu-berlin.de/de/mitarbeiter/1687995" className={classes.link} target="_top">
                            Isabel Dziobek
                        </Link>
                        <Typography>
                            Principal Investigator
                        </Typography>
                        <br/>
                        <Link href="http://www.tbrick.net/" className={classes.link} target="_top">
                            Timothy R. Brick
                        </Link>
                        <Typography>
                            Principal Investigator
                        </Typography>
                        <br/>
                        <Divider classes={{root: classes.dividerGutters}} variant="middle" />
                        <br/>

                            <Link href="https://jschn.github.io/thisemotiondoesnotexist/" className={classes.link}>
                                Learn more about the project
                            </Link>

                        </div>
                </Modal>
            </div>
        )
    }
}

HelpModal.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(HelpModal);