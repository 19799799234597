import core from 'mathjs/core';
import matrix from 'mathjs/lib/type/matrix'

import multiply from 'mathjs/lib/function/arithmetic/multiply';
import subtract from 'mathjs/lib/function/arithmetic/subtract';
import add from 'mathjs/lib/function/arithmetic/add';
import dotDivide from 'mathjs/lib/function/arithmetic/dotDivide';
import dotMultiply from 'mathjs/lib/function/arithmetic/dotMultiply';
import transpose from 'mathjs/lib/function/matrix/transpose';


const math = core.create();

math.import(matrix);
math.import(multiply);
math.import(subtract);
math.import(add);
math.import(dotDivide);
math.import(dotMultiply);
math.import(transpose);


const predict = (pls, emos, frame = null) => {
    /*
     + if frame is null, compte all frames
     */

    const {scaling, V, uSqrtD, nVarPerFrame } = pls;

    // scale emos
    let values = math.subtract(emos, scaling["xMean"]);
    // element wise division
    values = math.dotDivide(values, scaling["xScale"]);
    values = math.transpose(
        math.multiply(
            values, V
        )
    );
    let start, stop;

    if (frame == null){
        start = 0;
        stop = uSqrtD.length;
    } else if (frame.sequence){
        start = frame.frame * nVarPerFrame;
        stop = uSqrtD.length;
    } else {
        // compute prediction one frame at a time
        start = frame.frame * nVarPerFrame;
        stop = (frame.frame + 1) * nVarPerFrame;
    }

    values = math.multiply(
        // fixme: check if bounds are valid
        uSqrtD.slice(start, stop), values
    );
    values = math.dotMultiply(values, scaling["yScale"].slice(start, stop));
    values = math.add(values, scaling["yMean"].slice(start, stop));

    return values;
}

const getFrame = (values, nVarPerFrame, frame) => {
    const start = frame * nVarPerFrame;
    const stop = (frame + 1) * nVarPerFrame;
    return values.slice(start, stop);
}

const getCoordinates = (prediction) => {
    let x = [];
    let y = [];
    
    for (let i = 0; i < prediction.length; i = i + 2) {
        x.push(prediction[i]);
        y.push(prediction[i + 1]);
    }

    return {
        x: x,
        y: math.dotMultiply(y, -1)
    }
}


const getConnections = (pls) => {
    const connections = math.subtract(pls['connections'],1);

    let paths = [];
    let create_new_path;

    for (let conn of connections){
        create_new_path = true;
        for (let path of paths){
            if (path.includes(conn[0]) || path.includes(conn[1])){
                path.push(conn[0]);
                path.push(conn[1]);
                create_new_path = false;
                break;
            }
        }
        if (create_new_path){
            paths.push(conn);
        }
    }
    // now each array contains most of the points twice.
    // We want to remove the redundant points but close a circle if there is one

    let is_circle;

    for (let i = 0; i < paths.length; i++){
        let path = paths[i];
        is_circle = path[0] === path[path.length -1];

        path = Array.from(new Set(path));
        if (is_circle){
            path.push(path[0]);
        }
        paths[i] = path;
    }

    return paths
}


const pathCoordinates = (paths, style, coordinates) => {
    /*
     transform a set of paths (that define a face) and coordinates as 
     generated by the predict method to a set of plotly compatible path input objects
     */
    const _pathCoordinates = [];

    for (let path of paths){
        _pathCoordinates.push({
            ...style,
            x: path.map((point) => {
                return (coordinates.x[point]);
            }),
            y: path.map((point) => {
                return (coordinates.y[point]);
            })
        })
    }
    return _pathCoordinates;
}

const nItems = (model) => (
    model.scaling.yMean.length / model.nVarPerFrame[0]
)

export { 
    predict, 
    getCoordinates,
    getConnections, 
    pathCoordinates, 
    getFrame, 
    nItems 
};