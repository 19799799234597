import React from 'react';

import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { debounce } from "../utils/utils";

import { filterValues, setEmos } from './EmoSlider/EmoSlider.reducer'; 

const mapStateToProps = (state) => ({
    ratings: state.emos.ratings.ratings
})

const mapDispatchToProps = (dispatch) => ({
    setEmos: (emos) => dispatch(setEmos(emos)),
    filterValues: (query) => dispatch(filterValues(query))
})

const styles = (theme) => ({
    container: {
        width: "100%"
    },
    textField: {
        width: "100%",
        margin: `${2 * theme.spacing.unit} 0`
    }
})


class FilterField extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            value: ""
        };

        this.form = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.triggerFilter = debounce(this.props.filterValues,250).bind(this);
    }

    componentDidMount(){
        this.form.current.onsubmit = (e) =>{
            this.handleSubmit(e);
            e.preventDefault();
            return false;
        }
    }

    componentDidUpdate(){
        this.triggerFilter(this.state.value);
    }

    handleSubmit(e){
        const query = this.state.value;
        let rating = this.props.ratings.filter((rating) => rating.label.indexOf(query) > -1)
        // if there are more than one matches, check if one of them is an exact match
        if (rating.length > 1){
            rating = rating.filter((rating) => rating.label === query)
        }
        if (rating.length === 1){
            this.props.setEmos(rating[0].centroid)
        }
    };

    handleChange(event){
        this.setState({
            value: event.target.value
        });
    }

    render(){
        const { classes } = this.props;
        return (
            <form className={ classes.container } ref={this.form} noValidate autoComplete="off">
                <TextField
                    id="filter-query"
                    label="Search labels"
                    className={ classes.textField }
                    value={ this.state.value }
                    onChange={ this.handleChange }
                    margin="normal"
                    variant="outlined"
                />
            </form>
        )
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FilterField));