import React from 'react';

import { connect } from 'react-redux';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import LabelButton from "./LabelButton";

import { withStyles } from '@material-ui/core/styles';
import { setEmos } from "./EmoSlider/EmoSlider.reducer";
import FilterField from "./FilterField";

import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const styles = (theme) => ({
    list: {
        width: "100%"
    },
    container: {},
    gutters: {
        padding: '11px 22px',
    },
    labelGutters: {
        padding: "0 24px"
    },
    dividerGutters: {
        margin: "6px 0"
    }
})

const mapStateToProps = (state) => ({
    emos: state.emos.emoValues,
    ratings: state.emos.ratings.ratings,
    labelCenters: state.emos.labels.centers,
    similarities: state.emos.labels.similarities,
    query: state.emos.labels.query,
    animationActive: state.animation.triggered
})

const mapDispatchToProps = (dispatch) => ({
    setEmos: emos => dispatch(setEmos(emos))
})


class LabelList extends React.Component{

    constructor(props){
        super(props);
        this.ref = React.createRef();
    }
    
    handleClick(rating){
        if (!this.props.animationActive){
            this.props.setEmos(rating);
        }
    }

    componentDidUpdate(){
        this.ref.current.scrollTop = 0;
    }

    renderRatings(maxDissim, animationActive, rating, index){
        return (
            <ListItem button={!animationActive} onClick={
                    this.handleClick.bind(this, rating.centroid)
                } 
                key={ index }
            >
                <LabelButton 
                    label={ rating.label }
                    translation={ rating.original }
                    value={ rating.value }
                    highlight={ rating.highlight }
                    light={ rating.light }
                    maxDissim={ maxDissim }
                    >
                </LabelButton>
            </ListItem>
    )}
    

    render(){
        const { similarities, classes, query, animationActive } = this.props;
        let { ratings } = this.props;

        const N_SIMILAR_RATINGS = 5;

        ratings = ratings.map(
            (rating) => ({
                ...rating,
                value: similarities[rating.label],
                light: false
            })
        )

        const matching = [];
        let other = [];

        // filter for query
        if ((typeof query !== "undefined") && query.length > 0){
            const qlen = query.length;
            
            for (let ratingObj of ratings){
                const start = ratingObj.label.indexOf(query)
                if (start > -1){
                    matching.push({
                        ...ratingObj,
                        highlight: [start, start + qlen]
                    })
                } else {
                    other.push(ratingObj)
                }
            }
        } else {
            other = ratings;
        }

        /*
         * ratings are sorted by similarity;
         * take n most similar, then n most dissimilar, then sort the rest alphabetically 
         */

        const head = other.slice(0, N_SIMILAR_RATINGS);

        const body = other.slice(
            N_SIMILAR_RATINGS, 
            other.length - N_SIMILAR_RATINGS
        ).map(
            (rating) => ({
                ...rating,
                light: true
            })
        );

        const tail = other.slice(
            other.length - N_SIMILAR_RATINGS, 
            other.length
        );

        tail.reverse();

        const maxDissim = Math.abs(tail[tail.length-1].value);

        body.sort((item1, item2) => {
            if(item1.label < item2.label) { return -1; }
            if(item1.label > item2.label) { return 1; }
            return 0;
        })

        other = [...head, ...tail, ...body];

        const renderRatings = this.renderRatings.bind(this, maxDissim, animationActive);

        return (
            <Paper elevation={0} ref={this.ref} classes={ { root: classes.container + " " + classes.gutters } }>
                <div className={classes.gutters}>
                    <Typography variant="h5">
                        Emotion Labels
                    </Typography>
                </div>
                <Divider classes={{root: classes.dividerGutters}} variant="inset" />
                <Typography className={classes.labelGutters} color="textSecondary" variant="caption">
                    Click a label to display the respective emotion
                </Typography>
                <div className={classes.gutters}>
                    <FilterField />
                </div>
                <List classes={{ root: classes.list }}>
                {
                    matching.length > 0 && <React.Fragment>
                    <Divider classes={{root: classes.dividerGutters}} component="li" variant="inset" />
                    <li>
                        <Typography className={classes.labelGutters} color="textSecondary" variant="caption">
                            Labels matching query
                        </Typography>
                    </li>
                    </React.Fragment>
                }
                {  
                    matching.length > 0 && matching.map(
                        renderRatings
                    )
                }
                {
                    head.length > 0 && <React.Fragment>
                    <Divider classes={{root: classes.dividerGutters}} component="li" variant="inset" />
                    <li id="most_sim">
                        <Typography className={classes.labelGutters} color="textSecondary" variant="caption">
                            Labels with highest similarity
                        </Typography>
                    </li>
                    </React.Fragment>
                }
                {  
                    head.length > 0 && head.map(
                        renderRatings
                    )
                }
                {
                    tail.length > 0 && <React.Fragment>
                    <Divider classes={{root: classes.dividerGutters}} component="li" variant="inset" />
                    <li id ="least_sim">
                        <Typography className={classes.labelGutters} color="textSecondary" variant="caption">
                            Labels with highest dissimilarity
                        </Typography>
                    </li>
                    </React.Fragment>
                }
                {  
                    tail.length > 0 && tail.map(
                        renderRatings
                    )
                }
                {
                    body.length > 0 && <React.Fragment>
                    <Divider classes={{root: classes.dividerGutters}} component="li" variant="inset" />
                    <li>
                        <Typography className={classes.labelGutters} color="textSecondary" variant="caption">
                            Other labels
                        </Typography>
                    </li>
                    </React.Fragment>
                }
                {  
                    body.length > 0 && body.map(
                        renderRatings
                    )
                }

                </List>
            </Paper>
        )
    }
}


export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(LabelList));