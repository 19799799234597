
import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import PlaySlider from './PlaySlider';
import PlayButton from "./PlayButton";

const styles = (theme) => ({
    bottomBar: {
        flexGrow: 0,
        overflowX: "hidden",
        overflowY: "visible",
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        background: theme.palette.grey[200]
    },
    toolbar: theme.mixins.toolbar
})

const BottomBar = (props) => (
    <div className={ props.classes.bottomBar + " " + props.classes.toolbar}>
        <PlayButton/>
        <PlaySlider 
            max={props.max}/>
    </div>
);

export default withStyles(styles)(BottomBar);