import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import { connect } from "react-redux";
import { setAnimationTime, setSetSliderTime } from "./FacePlot/FacePlot.reducer";
import Slider from '@material-ui/lab/Slider';

import { INITIAL_TIME } from "../utils/globals"; 

const mapStateToProps = (state) => ({
    animationTriggered: state.animation.triggered,
})

const mapDispatchToProps = (dispatch) => ({
    setAnimationTime: (time) => dispatch(setAnimationTime(time)),
    setSetSliderTime: (callback) => dispatch(setSetSliderTime(callback))
})

const styles = (theme) => ({
    slider: {
        padding: '22px 0px',
        //maxWidth: '100%',
    },
    flexContainer: {
        flexGrow: 1,
        flexBasis: "80%",
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2
    }
})

class PlaySlider extends React.Component {
    constructor(props){
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.setSliderTime = this.setSliderTime.bind(this);
        this.state = {
            value: INITIAL_TIME
        }
        this.props.setSetSliderTime(this.setSliderTime);
    }

    setSliderTime(time){
        this.setState({
            value: time
        });
    }

    onDragEnd(){
        this.props.setAnimationTime(this.state.value);
    }

    onChange(event, value){
        this.setState({
            value: value
        })
        event.preventDefault();
    }

    render() {
        const { classes, max, animationTriggered } = this.props;
        return <div className={ classes.flexContainer }>
            <Slider
                classes={{ container: classes.slider }} 
                value={ this.state.value } 
                step={1}
                max={ max }
                onChange={ this.onChange }
                onDragEnd={ this.onDragEnd }
                disabled={ animationTriggered ? true : false }
                />
        </div>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PlaySlider));