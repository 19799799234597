import { getLabelCenters, getSimilarities, getLabelSds } from "../../utils/labelValues";

const sortRatings = (similarities, rating1, rating2) => {
    const d1 = similarities[rating1.label];
    const d2 = similarities[rating2.label];
    if (d1 > d2){
        return -1
    } else if (d1 < d2){
        return 1
    } else {
        return 0
    }
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const initialState = {
    emoValues : {
        "happy": getRandomInt(0, 100),
        "sad": getRandomInt(0, 50),
        "surprised":getRandomInt(0, 100),
        "disgusted": getRandomInt(0, 100),
        "angry": getRandomInt(0, 50),
        "fearful": getRandomInt(0, 100),
        "interested": getRandomInt(0, 100)
    },
    emoNames: [
        "happy", "sad", "surprised", "disgusted" ,"angry", 
        "fearful", "interested"
    ],
    ratings: {
        loaded: false,
    },
    labels: {
        centers: undefined,
        similarities: undefined,
        sdWeight: 3,
    }
};

/*
 * ACTIONS
 */

const SET_EMO = "SET_EMO";
const SET_EMOS = "SET_EMOS";
const RESET_EMOS = "RESET_EMOS";
const SET_RATINGS = "SET_RATINGS";
const FILTER_VALUES = "SET_FILTER_VALUES";
const SET_EMOS_RANDOM = "SET_EMOS_RANDOM";
const SET_WEIGHT = "SET_WEIGHT";


const setRatings = (ratings) => ({
    type: SET_RATINGS,
    ratings: ratings
})

const resetEmos = () => ({
    type: RESET_EMOS
})

const setEmosRandom = () => ({
    type: SET_EMOS_RANDOM
})

const setWeight = (weigth) => ({
    type: SET_WEIGHT,
    weight: weigth
})

const setEmo = (emo, value) => ({
    type: SET_EMO,
    emo: emo,
    value: value
})
const setEmos = (values) => ({
    type: SET_EMOS,
    values: values
})

const filterValues = (query) => ({
    type: FILTER_VALUES,
    query: query
})

const emoValuesReducer = (state, emoValues) => {
    let similarities;
    let ratings;

    if (typeof state.labels.centers !== "undefined"){
        similarities = getSimilarities(
            state.labels.centers, 
            state.labels.sds, 
            state.labels.sdWeight,
            emoValues
        );
        ratings = state.ratings.ratings.sort(
            sortRatings.bind(
                null, similarities
            )
        );
    } else {
        ratings = state.ratings.ratings;
    }

    return {
        ...state,
        emoValues: emoValues,
        ratings: {
            ...state.ratings,
            ratings: ratings
        },
        labels: {
            ...state.labels,
            similarities: similarities
        }
    }
}

const emos = (state = initialState, action) => {
    let similarities;
    let ratings;
    let emoValues;

    switch (action.type){
        case SET_EMO:
            emoValues = {
                ...state.emoValues,
                [action.emo]: action.value
            }
            return emoValuesReducer(state, emoValues);

        case SET_EMOS:
            if (typeof state.labels.centers !== "undefined"){
                similarities = getSimilarities(
                    state.labels.centers, 
                    state.labels.sds,
                    state.labels.sdWeight,
                    action.values
                );
                ratings = state.ratings.ratings.sort(
                    sortRatings.bind(
                        null, similarities
                    ));
            } else {
                ratings = state.ratings.ratings;
            }
            emoValues = {};

            Object.keys(state.emoValues).forEach((emo) => {
                emoValues[emo] = action.values[emo];
            })

            return {
                ...state,
                emoValues: emoValues,
                emoNames: Object.keys(emoValues),
                ratings: {
                    ...state.ratings,
                    ratings: ratings
                },
                labels: {
                    ...state.labels,
                    similarities: similarities
                }
            }
        case SET_WEIGHT:
            similarities = getSimilarities(
                state.labels.centers, 
                state.labels.sds,     
                action.weight,
                state.emoValues
            )
            ratings = state.ratings.ratings.sort(
                sortRatings.bind(
                    null, similarities
                )
            );

            return {
                ...state,
                ratings: {
                    ...state.ratings,
                    ratings: ratings
                },
                labels: {
                    ...state.labels,
                    sdWeight: action.weight,
                    similarities: similarities
                }
            }
        case SET_EMOS_RANDOM:
            emoValues = {};
            Object.keys(state.emoValues).forEach((emo) => {
                if (emo === "sad") {
                    emoValues[emo] = getRandomInt(0, 70);
                }
                else if (emo === "angry") {
                    emoValues[emo] = getRandomInt(0, 50);
                }
                else{
                    emoValues[emo] = getRandomInt(0, 100);
                }
            })
            return emoValuesReducer(state, emoValues);

        case SET_RATINGS:
            const centers = getLabelCenters(action.ratings);
            const sds = getLabelSds(action.ratings);
            similarities = getSimilarities(
                centers,
                sds,
                state.labels.sdWeight,
                state.emoValues
            )

            return {
                ...state,
                ratings: {
                    ...state.ratings,
                    ratingsLoaded: true,
                    ratings: action.ratings.sort(
                        sortRatings.bind(null, similarities)
                    )
                },
                labels: {
                    ...state.labels,
                    centers: centers,
                    sds: sds,
                    similarities: similarities
                }
            }
        case FILTER_VALUES:
            return {
                ...state,
                labels: {
                    ...state.labels,
                    query: action.query
                }   
            };
        case RESET_EMOS:
            //emoValues = {};
            //quick and dirty hack to RESET to the rating mean instead of 0
            emoValues = {
                "happy": 31,
                "sad": 27,
                "surprised":28,
                "disgusted": 20,
                "angry": 24,
                "fearful": 25,
                "interested": 40
            };

/*            Object.keys(state.emoValues).forEach((emo) => {
                 emoValues[emo] = 0;
            })*/
            return emoValuesReducer(state, emoValues);

        default:
            return state;
    }
}

export {
    emos,
    setEmo,
    setEmos,
    setRatings,
    filterValues,
    resetEmos,
    setEmosRandom,
    setWeight
}