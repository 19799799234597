import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const styles = (theme) => {
    return ({
        container: {
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
        },
        child: {
            flexBase: 1,
            width: "50%",
            position: "relative"
        },
        bar: {
            position: "absolute",
            left: 0,
            top: 0,
            background: theme.palette.primary.main,
            height: "100%",
        },
        dissimilarity: {
            backgroundColor: `${theme.palette.error.main} !important`
        },
        highlight: {
            background: theme.palette.text.hint
        }
    })
}

const LabelButton = (props) => {
    const { classes, label, highlight, value, maxDissim, light, translation } = props;
    let leftside;

    if (typeof highlight !== "undefined"){
        leftside = (
            <div className={ classes.child }>
                { label.slice(0, highlight[0]) }
                <span className={classes.highlight}>{label.slice(highlight[0], highlight[1])}</span>
                { label.slice(highlight[1], label.lenght)}
                <Typography color="textSecondary" variant="caption">
                    { translation }
                </Typography>
            </div>
        )
    } else {
        leftside = (
            <div className={ classes.child }>
                { label }
                <Typography color="textSecondary" variant="caption">
                    { translation }
                </Typography>
            </div>
        )
    }

    const totalWidth = 1 + maxDissim;

    let bgColor, offset, width;

    if (value > 0){
        // default palette primary main
        bgColor =  light ? "#7986cb" : "#3f51b5";
        offset = Math.round((maxDissim * 100 / totalWidth));
        width = Math.round((value * 100 / totalWidth))
    } else {
        // default palette error main
        bgColor = light ? "#e57373" : "#f44336";
        offset = Math.round((maxDissim + value) * 100 / totalWidth);
        width = Math.round((Math.abs(value) * 100 / totalWidth))
    }

    return (
        <div className={ classes.container }>
            { leftside }  
            <div className={ classes.child }>
                <span className={ `${classes.bar}` } style={{
                    width: `${width}%`,
                    left: `${offset}%`,
                    backgroundColor: bgColor
                }}/>
            </div>
        </div>
    )
}

export default withStyles(styles)(LabelButton);