import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import {PlayCircleOutline} from "@material-ui/icons";
import {Close} from "@material-ui/icons";
import HelpIcon from '@material-ui/icons/Help';
import Divider from '@material-ui/core/Divider';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: "50%",
        backgroundColor: '#f5f5f5',
        //backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: 'none',
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    clbut: {
        position: 'absolute',
        right: 5,
        top: 5,
    },
});





class InitialModal extends React.Component {
    state = {
        open: 0,
    };

    handleClose = () => {
        this.setState({ open: this.state.open + 1 });
    };

    flashById = (e_id) => {
        let el =document.getElementById(e_id);
        el.style.animation = "unset";
        void el.offsetWidth;
        el.style.animation = 'flash 1s';
    };

    render() {
        const { classes } = this.props;
        const { open } = this.state;
        return (
            <div>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={open === 0}
                    onClose={this.handleClose}
                    closeAfterTransition={true}
                >
                <div className={classes.paper} >
                    <Close onClick={this.handleClose} className={classes.clbut}/>
                    <Typography variant="button" id="modal-title">
                        <b>Welcome to thisemotiondoesnotexist</b>
                    </Typography>
                    <Divider classes={{root: classes.dividerGutters}} variant="middle" />
                    <br/>
                    <Typography>
                       How are emotions perceived from facial expressions?
                        <br/>
                        <br/>
                        We asked people to view and rate video clips of emotional facial expressions.
                        From these data we built a statistical model that captures the relationship between facial expressions and their emotional perception.
                        <br/>
                        <br/>
                        This app allows you to interactively explore this relationship. Enjoy!
                    </Typography>
                    <br/>
                    <Divider classes={{root: classes.dividerGutters}} variant="middle" />
                </div>
                </Modal>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={open === 1}
                    onClose={this.handleClose}
                    closeAfterTransition={true}
                >
                <div className={classes.paper} >
                    <Close onClick={this.handleClose} className={classes.clbut}/>
                    <Typography variant="button" id="modal-title">
                        <b>Instructions</b>
                    </Typography>
                    <Divider classes={{root: classes.dividerGutters}} variant="middle" />

                    <br/>
                    <br/>
                    <Typography >
                        <div style={{textAlign:'center'}}>
                        <ul style={{display:'inline-block', textAlign:'left'}}>
                        <li>
                        Hit  <Button  variant="outlined" onClick={()=>this.flashById("random_button")}  className={classes.button}><EqualizerIcon/>random</Button>  to generate a random emotion or manipulate the sliders on the left side individually.
                        </li>
                        <br/>
                        <br/>
                        <br/>
                        <li>
                    Click <IconButton
                            onClick={ ()=>this.flashById("play_button") }
                            variant="contained"
                            color="primary"
                            classes={{ root: classes.button }}
                        ><PlayCircleOutline fontSize="large"/></IconButton> in the bottom left corner to play the facial expression shown in the middle just like a video.
                        </li>

                        <br/>
                        <br/>
                        <br/>
                        <li>
                            The five emotion labels <span style={{cursor: 'pointer', color:"#3f51b5"}} onClick={()=>this.flashById("most_sim")}>most</span> and  <span style={{cursor: 'pointer', color:"#f44336"}} onClick={()=>this.flashById("least_sim")}> least </span>
                            similar to the current emotion are displayed on the right side.
                        </li>
                        <br/>
                        <br/>
                        <br/>
                        <li>
                        More information about the project and the people involved can be found here:
                            <IconButton
                                onClick={ ()=>this.flashById("help_button") }
                                variant="contained"
                                //color="primary"
                                classes={{ root: classes.button }}
                            >
                                <HelpIcon/> &nbsp; <Typography>What is this?</Typography>
                            </IconButton>
                        </li>
                        </ul>

                        </div>
                    </Typography>
                    <br/>


                    <Divider classes={{root: classes.dividerGutters}} variant="middle" />
                </div>
                </Modal>
            </div>
        );
    }
}

InitialModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InitialModal);
