import React from 'react';
import { predict, getCoordinates, pathCoordinates, getFrame, nItems } from '../../utils/predict';
import { setDone } from "./FacePlot.reducer";
import { connect } from 'react-redux';
import store from "../../store";
import Paper from '@material-ui/core/Paper';

import { FRAME_RATE } from "../../utils/globals";

const mapStateToProps = (state) => ({
    time: state.animation.time,
    animationTriggered: state.animation.triggered,
    emos: state.emos.emoValues,
    setSliderTime: state.animation.setSliderTime,
    showMarkers: state.animation.showMarkers
})

const mapDispatchToProps = (dispatch) => ({
    setDone: (time) => dispatch(setDone(time))
})

const getPlotLayout = () => {
    const axisOptions = {
        showgrid: false,
        zeroline: false,
        showline: false,
        autotick: true,
        ticks: '',
        showticklabels: false
    };
    
    return {
        showlegend: false,
        autosize: false,
        xaxis: axisOptions,
        yaxis: Object.assign(axisOptions),
        margin: {
            l: 0,
            r: 0,
            t: 0,
            b: 0
        }
    };
}

class FacePlot extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            hasRange: false,
            precomputed: false
        }
        this.interrupt = false;
        this.handleInterrupt = this.handleInterrupt.bind(this);
        this.unsubscribe = store.subscribe(this.handleInterrupt);
    }
    
    static getDerivedStateFromProps(props, state){
        const emos = Object.keys(props.emos).map((key) => props.emos[key])

        const style =  {
            mode: props.showMarkers ? 'lines+markers' : 'lines',
            line: {
              color: 'rgba(55, 128, 191,0.8)',
              width: 3
            }
        }

        if (props.animationTriggered && !state.precomputed){
            const getFrameValues = getFrame.bind(
                null, 
                // values of all frames
                predict(props.model, emos, {
                    frame: 0,
                    sequence: true
                }),
                props.model.nVarPerFrame
            )

            const getPathCoordinates = pathCoordinates.bind(
                null, 
                props.paths,
                style
            );

            const _pathCoordinates = [];

            for (let i = 0; i < nItems(props.model); ++i){
                _pathCoordinates.push(
                    getPathCoordinates(
                        getCoordinates(
                            getFrameValues(i)
                        )
                    )
                );
            }

            return {
                pathCoordinates: _pathCoordinates,
                precomputed: true
            }
        } else if (!props.animationTriggered){
            // get value vector for emotions
            const coordinates = getCoordinates(predict(props.model, emos, {
                frame: props.time,
                sequence: false
            }));
            const _pathCoordinates = pathCoordinates(
                props.paths,
                style, 
                coordinates
            )
            // calculate plot range only once and keep it constant afterwards
            if (typeof state.range === "undefined"){
                // scaling
                const S = 10;

                const xmin = Math.floor(Math.min.apply(Math, coordinates.x) * S) / S;
                const xmax = Math.ceil(Math.max.apply(Math, coordinates.x) * S) / S;

                const ymin = Math.floor(Math.min.apply(Math, coordinates.y) * S) / S;
                const ymax = Math.ceil(Math.max.apply(Math, coordinates.y) * S) / S;
                
                props.layout.xaxis = Object.assign(
                    {
                        range: [
                            xmin,
                            xmax
                        ]
                    }, props.layout.xaxis
                );

                props.layout.yaxis = Object.assign(
                    {
                        range: [
                            ymin,
                            ymax
                        ]
                    }, props.layout.yaxis
                );

                state.aspectRatio = (ymax - ymin) / (xmax - xmin);
            }

            return {
                pathCoordinates: _pathCoordinates,
                range: true,
                aspectRatio: state.aspectRatio,
                precomputed: false,
            }
        } else {
            return null;
        }
    }

    handleInterrupt() {
        this.interrupt = store.getState().animation.interruption;
    }

    componentDidMount(){
        const elem = document.getElementById("plotContainer");
        const padding = 8;

        if (elem.offsetHeight > elem.offsetWidth){
            this.props.layout.width = elem.offsetWidth - padding;
            this.props.layout.height = this.props.layout.width * this.state.aspectRatio;
        } else {
            this.props.layout.height = elem.offsetHeight - padding;
            this.props.layout.width = this.props.layout.height / this.state.aspectRatio;
        };

        this.props.Plotly.newPlot(
            elem,
            this.state.pathCoordinates,
            this.props.layout,
            this.props.plotOptions
        );
    }

    componentDidUpdate(){
        if (this.props.animationTriggered && this.state.precomputed && (typeof this.props.setSliderTime !== "undefined")){
            this.playSequence();
        } else {
            this.props.Plotly.animate(
                document.getElementById("plotContainer"),
                {
                    data: this.state.pathCoordinates,
                },
                {
                    transition: {
                        duration: 200,
                        easing: 'cubic-in-out'
                    }
                }
            );
        }
    }

    /*
     * returns an iterator over path connections that is in sync with 
     * time slider via setTime
     */
    generateIterator(_pathCoordinates, setTime){
        let nextIndex = 0;
        return {
            next: () => {
                setTime(nextIndex);
                if (nextIndex < (_pathCoordinates.length - 1) && !this.interrupt) {
                    return {
                        value: _pathCoordinates[nextIndex++],
                        done: false,
                        interrupt: false,
                        index: nextIndex
                    };
                } else {
                    return {
                        index: nextIndex,
                        done: true,
                        interrupt: this.interrupt ? true : false,
                    };
                }
            }
        };
    }

    playSequence(){
        const targetElement = document.getElementById('plotContainer');          

        const getNextFrame = this.generateIterator(
            this.state.pathCoordinates, 
            this.props.setSliderTime
        );

        const FRAME_PERIOD = 1000 / FRAME_RATE;
        let lastTime = 0;

        const update = (time) => {
            if (time - lastTime >= FRAME_PERIOD){
                const currentFrame = getNextFrame.next();
                if (!currentFrame.done){
                    this.props.Plotly.animate(
                        targetElement,
                        {
                            data: currentFrame.value,
                        },
                        { 
                            transition: {
                                duration: FRAME_PERIOD / 2,
                                easing: 'linear'
                            },        
                            frame: {
                                duration: FRAME_PERIOD / 2,
                                redraw: false,
                            }
                          
                        }
                    );
                    lastTime = time;
                } else if (currentFrame.interrupt){
                    this.props.setDone(currentFrame.index);
                    return;
                } else {
                    this.props.setSliderTime(this.props.time);
                    this.props.setDone(this.props.time);
                    return;
                }
            }
            requestAnimationFrame(update);
        };

        // start sequence
        requestAnimationFrame(update);
    } // playSequence end

    render(){
        const {className} = this.props; 
        return (
            <Paper elevation={0} id="plotContainer" classes={ { root: className } }>
            </Paper>
        )
    }
}

FacePlot.defaultProps = {
    plotOptions: {
        scrollZoom: false,
        staticPlot: true,
        responsive: true,
    },
    layout: getPlotLayout()
}

export default connect(mapStateToProps, mapDispatchToProps)(FacePlot);
