
const arrayEquals = (array1, array2) => (
    array1.length === array2.length && 
    array1.sort().every((value, index) => (value === array2.sort()[index]))
);

const schema = {
    type: "object",
    elements: {
        "V": {
            type: "array",
            length: 7,
            elements: {
                type: "array",
                length: 7,
                elements: {
                    type: "number"
                }
            }
        },
        "scaling": {
            type: "object",
            elements: {
                "xMean": {
                    type: "array",
                    length: 7,
                    elements: {
                        type: "number"
                    }
                },
                "xScale": {
                    type: "array",
                    length: 7,
                    elements: {
                        type: "number"
                    }
                },
                "yMean": {
                    type: "array",
                    length: 13600,
                    elements: {
                        type: "number"
                    }
                },
                "yScale": {
                    type: "array",
                    length: 13600,
                    elements: {
                        type: "number"
                    }
                }
            }
        },
        "uSqrtD": {
            type: "array",
            length: 13600,
            elements: {
                type: "array",
                length: 7,
                elements: {
                    type: "number"
                }
            }
        },
        "connections": {
            type: "array",
            length: 63,
            elements: {
                type: "array",
                length: 2,
                elements: {
                    type: "number"
                }
            }
        },
        "nVarPerFrame": {
            type: "array",
            length: 1,
            elements: {
                type: "number"
            }
        }
    }
}

const _validate = (schema, object) => {
    let valid = true;
    switch (schema.type){
        case "object":
            valid &= arrayEquals(Object.keys(object), Object.keys(schema.elements));
            Object.keys(schema.elements).forEach(
                (key) => {
                    valid &= _validate(schema.elements[key], object[key])
                });
            return valid
        case "array":
            valid &= (schema.length === object.length);
            object.forEach((item) => {
                valid &= _validate(schema.elements, item)
            })
            return valid
        case "number":
            return (typeof(object) === "number")
        default:
            return false
    }
}

const validate = _validate.bind(null, schema);

export {
    validate
};