import React from 'react';

import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/lab/Slider';
import { setEmo } from './EmoSlider.reducer';
import { connect } from 'react-redux';

import { Add, Remove, ArrowDropUp } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import { debounce } from "../../utils/utils";

const mapStateToProps = (state) => ({
    animationActive: state.animation.triggered
})

const mapDispatchToProps = (dispatch) => ({
    setEmo: (emo, value) => dispatch(setEmo(emo, value))
})

const styles = (theme) => {
    return ({
    sliderWrapper: {
        display: "flex",
    },
    sliderAnnotation: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
    },
    icon: {
        flexGrow: 0,
        width: "30px",
        padding: "0 6px",
        alignSelf: "center"
    },
    trackError: {
        backgroundColor: theme.palette.error.light
    },
    thumbError: {
        backgroundColor: theme.palette.error.main
    },
    slider: {
        width: "100%",
        flexGrow: 1,
        padding: '22px 0px',
    },
    itemGroup: {
        width: "100%",
    }
})};

const errorTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },    
    palette: {
        primary: { main: "#f44336" },
    }
});
  
const defaultTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },    
    palette: {
        primary: { main: "#1769aa" },
    }
});


class EmoSlider extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            value: props.value,
            active: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.triggerResponse = debounce(
            this.triggerResponse.bind(this),
            250
        );
    }

    static getDerivedStateFromProps(props, state){
        if (!(state.active)){
            return {
                value: props.value
            }
        }
        return null;
    }

    triggerResponse(){
        if (!this.props.animationActive){
            this.props.setEmo(this.props.emo, this.state.value);
            this.setState({
                active: false
            })
        }
    }

    handleChange(event, value){
        if (!this.props.animationActive){
            this.setState({
                value: value,
                active: true
            });
            this.triggerResponse();
        }
        event.preventDefault()
    }

    render(){
        const { emo, classes, animationActive } = this.props;
        const value = this.state.value;
        let theme; 

        if (value < 0){
            theme = errorTheme
        } else {
            theme = defaultTheme
        }

        return(
            <div className={classes.itemGroup}>
                <Typography id="label">{emo}</Typography>
                <div className={classes.sliderWrapper}>
                    <Remove classes={{root: classes.icon}}/>
                    <MuiThemeProvider theme={theme}>
                        <Tooltip title={`Pick a value for emotion ${emo}`} enterDelay={1000} placement="top">
                            <Slider
                                classes={{
                                    container: classes.slider
                                }}
                                value={value}
                                aria-labelledby="label"
                                onChange={ this.handleChange }
                                //onDragEnd={ this.onDragEnd }
                                disabled={ animationActive }
                                min={-100}
                                max={100}
                            />
                        </Tooltip>
                    </MuiThemeProvider>
                    <Add classes={{root: classes.icon}}/>
                </div>
                <div className={classes.sliderAnnotation}>
                    <ArrowDropUp classes={{ root: classes.centerIcon }}/>
                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EmoSlider));