import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import Switch from '@material-ui/core/Switch';

import { withStyles } from '@material-ui/core/styles';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Divider from '@material-ui/core/Divider';

import { connect } from "react-redux";
import { setShowMarkers } from "./FacePlot/FacePlot.reducer";

import SDSlider from "./SDSlider";
import Tooltip from '@material-ui/core/Tooltip';


const mapStateToProps = (state) => ({
    showMarkers: state.animation.showMarkers
})

const mapDispatchToProps = (dispatch) => ({
    setShowMarkers: (value) => dispatch(setShowMarkers(value))
})

const styles = {
    padded: {
        padding: "0 1rem",
        '& *:focus': {
            outline: 0
        }
    },
    withMargin: {
        margin: ".5rem 0"
    }
};
  

class SettingsMenu extends React.Component {

    constructor(props){
        super(props);
        this.handleMarkers = this.handleMarkers.bind(this);
    }

    state = {
        anchorEl: null,
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleMarkers = () => {
        this.props.setShowMarkers(!this.props.showMarkers);
    }

    render() {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        const { classes, showMarkers } = this.props;

        return (
            <div>
                <Tooltip enterDelay={100} title="Settings" placement="left">
                    <IconButton
                        aria-label="More"
                        aria-owns={open ? 'long-menu' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                        >
                        <SettingsIcon />
                    </IconButton>
                </Tooltip>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={this.handleClose}
                    classes={{ paper: classes.padded}}
                    >
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Settings</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={showMarkers}
                                            onChange={this.handleMarkers}
                                            value="showMarkers"
                                            color="primary"
                                        />
                                    }
                                    label="Show vertices"
                                />
                                <Divider variant="fullWidth" classes={{ root: classes.withMargin }}/>
                                <SDSlider/>
                            </FormGroup>
                        </FormControl>
                </Menu>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SettingsMenu));
