import React from 'react';
import './App.css';

import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';

import FacePlot from "./components/FacePlot/FacePlot";
import TopBar from './components/TopBar';
import BottomBar from "./components/BottomBar";
import { nItems } from './utils/predict';
import { withStyles } from '@material-ui/core/styles';
import { getConnections } from './utils/predict';
import { validate } from './utils/validate';

import { setRatings } from "./components/EmoSlider/EmoSlider.reducer";
import { connect } from "react-redux";

import EmoList from "./components/EmoList";
import FunctionalSidebar from "./components/FunctionalSidebar";

import InitialModal from "./components/InitialModal";

const mapDispatchToProps = (dispatch) => ({
    setRatings: (ratings) => dispatch(setRatings(ratings))
})

const mapStateToProps = (state) => ({
    ratingsLoaded: state.emos.ratings.ratingsLoaded
})

const styles = (theme) => ({
    appContainer: {
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column"
    },
    mainContainer: {
        display: "flex",
        flexGrow: 1,
        alignSelf: "stretch",
        flexWrap: "no-wrap",
        minHeight: 0,
    },
    plot: {
        alignSelf: "stretch",
        flexGrow: 1,
        // fixme: layout
        /*flexBasis: "66%",
        maxWidth: "66%",*/
        width: "100vh",
        justifyContent: "flex-end",
    },
    sideBar: {
        flexBasis: "33%",
        maxWidth: "25rem",
        alignSelf: "stretch",
        flexGrow: 1,
        overflowY: "scroll",
        overflowX: "hidden",
    },
    bottomContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 0, 
        margin: 0
    },
    progress: {
        margin: theme.spacing.unit * 2,
    },
    error: {
        margin: theme.spacing.unit * 2,
        color: theme.palette.error.main
    }
})

class App extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            Plotly: undefined,
            models: [],
            error: false
        }
    }

    isPending(){
        if ((typeof this.state.Plotly !== "undefined") && 
            (this.state.models.length > 0)){
            return false
        } else {
            return true
        }
    }

    componentDidMount(){
        this.fetchModel();
        this.fetchPlotly();
        this.fetchDimRatings();
    }

    fetchPlotly(){
        import(
            /* webpackMode: "lazy" */
            'plotly.js-basic-dist'
        ).then((Plotly) => {
            this.setState({
                Plotly: Plotly
            });
        });
    }

    fetchModel(){
        let url = process.env.PUBLIC_URL;
        const urlSuffix = 'data/pls.json';
        if (!url.endsWith('/')){
            url += "/";
        }
        url += urlSuffix;
        fetch(url).then(
            (response) => response.json()
        ).then(
            (model) => {

                if (validate(model)){
                    this.setState({
                        models: [model],
                        paths: getConnections(model)
                    })
                } else {
                    this.setState({
                        error: true
                    })
                }
            },
            () => {
                this.setState({
                    error: true
                })
            }
        )
    }

    fetchDimRatings(){
        let url = process.env.PUBLIC_URL;
        const urlSuffix = 'data/dimRatingsPooled.json';
        if (!url.endsWith('/')){
            url += "/";
        }
        url += urlSuffix;
        fetch(url).then(
            (response) => response.json()
        ).then(
            (ratings) => {
                this.props.setRatings(ratings)
            }
        )
    }

    render(){
        const { classes, ratingsLoaded } = this.props;
        const { Plotly, error, models, paths }  = this.state;

        let mainContent;

        if (error) {
            mainContent = <div className={classes.error}>Error: Resource not found</div>
        } else if (this.isPending()){
            mainContent = (
                <div className={classes.bottomContainer}>
                    <CircularProgress className={classes.progress}/>
                </div>
            )
        } else {
            mainContent = <React.Fragment>
                <InitialModal/>
                <div className={ classes.mainContainer }>
                <EmoList classes={ { container: classes.sideBar }}/>
                <FacePlot
                    className={ classes.plot }
                    model={ models[0] }
                    paths={ paths }
                    Plotly={ Plotly }
                />
                { ratingsLoaded && 
                    <FunctionalSidebar 
                        classes={ { container: classes.sideBar } } 
                        Plotly={ Plotly }
                        />
                }
                </div>
                <BottomBar max={nItems(models[0]) - 1}/>
            </React.Fragment>
        }

        return <div className={ classes.appContainer }>
            <CssBaseline />
            <TopBar showSideBarButtons={ ratingsLoaded }>
                thisemotiondoesnotexist
            </TopBar>
            { mainContent }
        </div>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles)(App));
