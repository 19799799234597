import { INITIAL_TIME } from '../../utils/globals';

const initialState = {
    triggered: false,
    showMarkers: false,
    active: false,
    setSliderTime: undefined,
    interruption: false, 
    time: INITIAL_TIME
}

const SET_SHOW_MARKERS = "SET_SHOW_MARKERS";
const SET_TRIGGERED = "SET_TRIGGERED";
const SET_SET_SLIDER_TIME = "SET_SET_SLIDER_TIME";
const SET_ANIMATION_TIME = "SET_ANIMATION_TIME";
const SET_DONE = "SET_DONE";
const SET_DONE_AND_RESET = "SET_DONE_AND_RESET";

const SET_INTERRUPTION = "SET_INTERRUPTION";

const setSetSliderTime = (callback) => ({
    type: SET_SET_SLIDER_TIME,
    setSliderTime: callback
})

const setShowMarkers = (show) => ({
    type: SET_SHOW_MARKERS,
    show: show
})

const setAnimationTime = (time) => ({
    type: SET_ANIMATION_TIME,
    animationTime: time
})

const setTriggered = () => ({
    type: SET_TRIGGERED,
    triggered: true
})

const setInterruption = () => ({
    type: SET_INTERRUPTION,
    interruption: true
})

const setDone = (currentTime) => {
    if (typeof currentTime !== "undefined"){
        return {
            type: SET_DONE,
            triggered: false,
            active: false,
            interruption: false,
            time: currentTime
        }
    } else {
        return {
            type: SET_DONE_AND_RESET,
            triggered: false,
            active: false,
            interruption: false
        }
    }
};

const animation = (state = initialState, action) => {
    switch (action.type){
        case SET_TRIGGERED:
            return {
                ...state,
                triggered: action.triggered
            }
        case SET_SET_SLIDER_TIME:
            return {
                ...state,
                setSliderTime: action.setSliderTime
            }
        case SET_ANIMATION_TIME:
            return {
                ...state,
                time: action.animationTime
            }
        case SET_DONE:
            return {
                ...state,
                triggered: action.triggered,
                active: action.active,
                time: action.time,
                interruption: action.interruption
            }
        case SET_DONE_AND_RESET:
            return {
                ...state,
                triggered: action.triggered,
                active: action.active,
                interruption: action.interruption
            }
        case SET_INTERRUPTION:
            return {
                ...state,
                interruption: action.interruption
            }
        case SET_SHOW_MARKERS:
            return {
                ...state,
                showMarkers: action.show
            }
        default:
            return state;
    }
}

export {
    animation,
    setDone,
    setTriggered,
    setAnimationTime,
    setSetSliderTime,
    setInterruption,
    setShowMarkers
}