import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import store from "./store"

import './index.css';
import App from './App';

import { BrowserRouter as Router } from 'react-router-dom';


ReactDOM.render(
    <Router basename={process.env.PUBLIC_URL}>
        <Provider store={store}>
            <App />
        </Provider>
    </Router>
    ,document.getElementById('root')
);