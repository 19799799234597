import React from 'react';

import { connect } from 'react-redux';

import EmoSlider from "./EmoSlider/EmoSlider";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { resetEmos, setEmosRandom } from "./EmoSlider/EmoSlider.reducer";

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import EqualizerIcon from '@material-ui/icons/Equalizer';

import Tooltip from '@material-ui/core/Tooltip';

const styles = (theme) => ({
    list: {
        width: "100%"
    },
    container: {},
    gutters: {
        padding: '12px 24px',
    },
    dividerGutters: {
        margin: "6px 0"
    },
    labelGutters: {
        padding: "0 24px"
    },
    buttons: {
        display: "flex",
        justifyContent: "space-around"
    },
    button: {
        margin: theme.spacing.unit,
        flexBasis: 0,
        flexGrow: 1,
        '& svg': {
            marginRight: "6px",
        }
    },
})

const mapStateToProps = (state) => ({
    emos: state.emos.emoValues,
    animationActive: state.animation.triggered
})

const mapDispatchToProps = (dispatch) => ({
    resetEmos: () => dispatch(resetEmos()),
    setEmosRandom: () => dispatch(setEmosRandom())
})




const EmoList = (props) => {
    const { emos, classes } = props;
    return (
        <Paper elevation={0} classes={{root: classes.container }}>
            <div className={classes.gutters}>
                <Typography variant="h5">
                    Emotion Perception
                </Typography>
            </div>
            <Divider classes={{root: classes.dividerGutters}} variant="inset" />
            <div className={classes.gutters + " " + classes.buttons}>
                <Tooltip enterDelay={500} title="Set emotion dimensions to random values" placement="bottom">
                    <Button disabled={props.animationActive} variant="outlined" onClick={props.setEmosRandom} className={classes.button} id="random_button">
                        <EqualizerIcon/>random
                    </Button>
                </Tooltip>
                <Tooltip enterDelay={500} title="Reset all emotion dimensions to the neutral position" placement="bottom">
                    <Button disabled={props.animationActive} variant="outlined" onClick={props.resetEmos} className={classes.button}>
                        <RotateLeftIcon/>reset
                    </Button>
                </Tooltip>
            </div>
            <Divider classes={{root: classes.dividerGutters}} component="div" variant="inset" />
            <div>
                <Typography className={classes.labelGutters} color="textSecondary" variant="caption">
                    Emotion dimensions (red = negative, blue = positive)
                </Typography>
            </div>
            <List classes={{ root: classes.list }}>
                { 
                    Object.keys(emos).map((emo, index) => (
                        <ListItem classes={{ gutters: classes.gutters }} key={index}>
                            <EmoSlider
                                emo={emo}
                                value={emos[emo]}
                                classes={{padding: classes.padding}}
                            />
                        </ListItem>
                    ))
                }
                </List>
        </Paper>
    )
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(EmoList));