import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import SettingsMenu from './SettingsMenu';
import HelpModal from './HelpModal';
import Link from "@material-ui/core/Link";



const styles = theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    elements: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline"
    }
});

const TopBar = (props) => {
    const { classes } = props;

    return (
        <AppBar 
            className={ classes.appBar } 
            position="static" 
            color="default"
        >
            <Toolbar classes={ { root: classes.toolbar } }>
                <div className={classes.elements}>
                    <Typography variant="h6" color="inherit">
                    { props.children }
                    </Typography>
                </div>
                <div className={classes.elements} >
                    <SettingsMenu key="btn1"/>
                    <div className={classes.elements} variant="contained" id="help_button" >
                        <HelpModal  key="btn2"/>

                        <Link href="https://jschn.github.io/thisemotiondoesnotexist/" className={classes.link}>
                            What is this?
                        </Link>
                    </div>
                </div>
                
            </Toolbar>

        </AppBar>
    );
}

TopBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TopBar);
