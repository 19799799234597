import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/lab/Slider';

import { connect } from 'react-redux';
import { setWeight } from './EmoSlider/EmoSlider.reducer';

import { debounce } from "../utils/utils";

const mapStateToProps = (state) => ({
    value: state.emos.labels.sdWeight
})

const mapDispatchToProps = (dispatch) => ({
    setValue: (value) => dispatch(setWeight(value))
})

const styles = {
    root: {
        width: 300,
    },
    slider: {
        padding: '22px 0px',
        width: "100%",
        flexGrow: 1
    },
    sliderWrapper: {
        display: "flex",
        flexWrap: "wrap"
    },
    sliderAnnotation: {
        display: "flex",
        width: "100%",
        flexGrow: 1,
        justifyContent: "space-between",
        alignItems: "top"
    },
    legendItem: {
        fontSize: "small",
        fontWeight: "light"
    }
};

class SDSlider extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            value: props.value,
            active: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.triggerResponse = debounce(
            this.triggerResponse.bind(this),
            250
        );
    }

    static getDerivedStateFromProps(props, state){
        if (!(state.active)){
            return {
                value: props.value
            }
        }
        return null;
    }

    triggerResponse(){
        this.props.setValue(this.state.value);
        this.setState({
            active: false
        })
    }

    handleChange(event, value){
        this.setState({
            value: value,
            active: true
        });
        this.triggerResponse();
        event.preventDefault()
    }

    render() {
        const { classes } = this.props;
        const { value } = this.state;

        const range = [0, 1, 2, 3, 4, 5];

        return (
            <div className={classes.root}>
                <Typography id="label">Set label distance weight</Typography>
                <div className={classes.sliderWrapper}>
                    <Slider
                        classes={{ container: classes.slider }}
                        value={value}
                        aria-labelledby="label"
                        onChange={this.handleChange}
                        min={range[0]}
                        max={range[5]}
                        step={0.25}
                    />
                    <div className={classes.sliderAnnotation}>
                        {
                            range.map(
                                (item, index) => <Typography key={`t${index}`} classes={{root: classes.legendItem}}>{item}</Typography>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

SDSlider.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SDSlider));
