const FACTOR = 3;
const N_LABELS = 5;


const getLabelCenters = (ratings) => {
    /*
     * emoNames is array of emoNames (not object with names and values) 
     */
    const center = {};
    for (let rating of ratings){
        center[rating.label] = rating.centroid;
    }
    return center;
}

const getLabelSds = (ratings) => {
    /*
     * emoNames is array of emoNames (not object with names and values) 
     */
    const sds = {};
    for (let rating of ratings){
        sds[rating.label] = rating.sd;
    }
    return sds;
}



const getLabelRegions = (emos, ratings) => {
    const bounds = {};
    const center = {};
    const radius = {};

    for (let rating of ratings){
        bounds[rating.label] = {};
        center[rating.label] = {}

        let radius_ = 0;

        for (let emo of Object.keys(emos)){
            const dist = FACTOR * rating[emo].sd;
            bounds[rating.label][emo] = {
                upper: rating[emo].mean + dist,
                lower: rating[emo].mean - dist
            }
            center[rating.label][emo] = rating[emo].mean;
            radius_ += dist ** 2;
        }

        radius[rating.label] = Math.sqrt(radius_);
    }

    return {
        bounds: bounds,
        center: center,
        radius: radius
    }    
}

const getValidLabels = (labelRegions, emos) => {
    const _getLabelDistance = getLabelDistance.bind(null, emos);
    const valid = [];

    Object.keys(labelRegions.bounds).forEach((label) => {
        let labelIsValid = true;
        for (let emo of Object.keys(emos)){
            // if emo vector is outside of bounds
            if (labelRegions.bounds[label][emo].upper < emos[emo] || labelRegions.bounds[label][emo].lower > emos[emo]){
                labelIsValid = false;
                break;
            }
        }
        if (labelIsValid){
            const distance = _getLabelDistance(labelRegions.center[label]);
            valid.push({
                label: label,
                fraction: (labelRegions.radius[label] - distance) / labelRegions.radius[label],
            })
        }
    })
    return valid.sort((label1, label2) => {
       if (label1.fraction > label2.fraction){
           return -1
       } else if (label1.fraction < label2.fraction){
           return 1
       } else {
           return 0
       }
    }).slice(0, N_LABELS);
}

const getLabelDistance = (emos, center) => {
    let distance = 0;
    Object.keys(center).forEach(
        (key) => {
            distance += (center[key] - emos[key]) ** 2;
        }
    );

    return Math.sqrt(distance);
}

const getSimilarities = (labelCenters, labelSds, sdWeight, emos) => {
    const _getLabelDistance = getLabelDistance.bind(null, emos);
    const similarities = {}

    Object.keys(labelCenters).forEach((label) => {
        //similarities[label] = 1 / (1 + (_getLabelDistance(labelCenters[label]) / (sdWeight * labelSds[label])))        
        similarities[label] = 1 - (_getLabelDistance(labelCenters[label]) / (sdWeight * labelSds[label]))
    })

    return similarities;
}

export {
    getLabelRegions,
    getValidLabels,
    getSimilarities,
    getLabelCenters,
    getLabelSds
}